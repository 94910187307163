import { Button, Icon, IconButton, Tooltip } from "@adasupport/byron";
import React from "react";

import { Flex, FlexColumn, FlexV, useReadOnly } from "components/Common";
import { useFilterOptionsDict } from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/hooks";
import {
  RULE_DSL,
  type RuleCondition,
  type RuleDefinition,
  type RuleGroup,
  isRuleGroup,
} from "services/rules";
import { type Variable, useVariables } from "services/variables";

import { RuleConditionComponentV1 } from "./RuleConditionComponentV1";
import {
  RULE_GROUP_OPERATOR_WIDTH,
  RuleGroupOperator,
} from "./RuleGroupOperatorV1";
import { getDefaultRightArgumentValue } from "./helpers";

export const getUpdatedRuleGroup = <RG extends RuleGroup>(
  variables: Variable[],
  ruleGroup: RG,
  rule: RuleCondition,
  newRule: RuleCondition,
): RG => {
  let newGroupArgs = ruleGroup.args;

  // If first argument (variable) changes...
  if (newRule.args[0].id !== rule.args[0].id) {
    const [newVariable, currentVariable] = [
      variables.find((v) => v._id === newRule.args[0].id),
      variables.find((v) => v._id === rule.args[0].id),
    ];

    // ...and new variable type is incompatible with current variable type...
    if (newVariable?._type !== currentVariable?._type) {
      // ...remove other rules from the group
      newGroupArgs = [rule];
    }
  }

  newGroupArgs = newGroupArgs.map((r) =>
    r === rule
      ? newRule
      : ({
          ...r,
          // Change variable in other rules to match the new variable
          args: [newRule.args[0], ...r.args.slice(1)],
        } as typeof r),
  );

  return {
    ...ruleGroup,
    args: newGroupArgs,
  };
};

export const RuleGroupComponent = <RG extends RuleGroup>({
  ruleGroup,
  onChange,
  variables,
  highlightInvalidFields,
  predefinedValues = {},
  isDisabled,
}: {
  ruleGroup: RG;
  onChange: (ruleGroup: RG) => void;
  variables: Variable[];
  highlightInvalidFields?: boolean;
  predefinedValues: {
    [key: string]: { value: string; label: string }[] | undefined;
  };
  isDisabled: boolean;
}) => (
  <FlexV gap>
    {ruleGroup.args.map((rule, index) => {
      if (isRuleGroup(rule)) {
        return (
          <Flex
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            gap
          >
            <Flex justifyContent="flex-end">
              <RuleGroupOperator ruleGroup={ruleGroup} index={index} />
            </Flex>
            <FlexColumn grow={1} shrink={1}>
              <RuleGroupComponent
                ruleGroup={rule}
                onChange={(value) => {
                  if (value.args.length === 0) {
                    onChange({
                      ...ruleGroup,
                      args: ruleGroup.args.filter((r) => r !== rule),
                    });

                    return;
                  }

                  onChange({
                    ...ruleGroup,
                    args: ruleGroup.args.map((r) => (r === rule ? value : r)),
                  });
                }}
                highlightInvalidFields={highlightInvalidFields}
                variables={variables}
                predefinedValues={predefinedValues}
                isDisabled={isDisabled}
              />
            </FlexColumn>
          </Flex>
        );
      }

      const [leftArg] = rule.args;
      const variableArgument = variables.find((v) => v._id === leftArg.id);
      const showAddRuleButton = variableArgument?._type !== "bool";

      return (
        <Flex
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          gap
        >
          <RuleConditionComponentV1
            rule={rule}
            onChange={(value) => {
              onChange(getUpdatedRuleGroup(variables, ruleGroup, rule, value));
            }}
            ruleGroup={ruleGroup}
            variables={variables}
            highlightInvalidFields={highlightInvalidFields}
            predefinedValues={predefinedValues}
            isDisabled={isDisabled}
          />
          <FlexColumn hidden={!showAddRuleButton}>
            <Tooltip
              text={(() => {
                switch (ruleGroup.name) {
                  case RULE_DSL.ALL:
                    return "And";
                  case RULE_DSL.ANY:
                    return "Or";
                  default:
                    // Will never happen
                    return "Unknown operator";
                }
              })()}
            >
              <IconButton
                variant="tertiary"
                aria-label="Add"
                icon={Icon.Add}
                isDisabled={!showAddRuleButton || isDisabled}
                onClick={() => {
                  const defaultRightArg = getDefaultRightArgumentValue(
                    variableArgument?._type,
                    rule.name,
                  );

                  onChange({
                    ...ruleGroup,
                    args: [
                      ...ruleGroup.args.slice(0, index + 1),
                      {
                        name: rule.name,
                        args:
                          defaultRightArg === undefined
                            ? [rule.args[0]]
                            : [rule.args[0], defaultRightArg],
                      },
                      ...ruleGroup.args.slice(index + 1),
                    ],
                  });
                }}
              />
            </Tooltip>
          </FlexColumn>

          <FlexColumn>
            <IconButton
              isDisabled={isDisabled}
              variant="tertiary"
              aria-label="Remove"
              icon={Icon.Trash}
              onClick={() => {
                onChange({
                  ...ruleGroup,
                  args: ruleGroup.args.filter((r) => r !== rule),
                });
              }}
            />
          </FlexColumn>
        </Flex>
      );
    })}

    {/** We only support ALL of ANY's in UI */}
    {ruleGroup.name === RULE_DSL.ALL && (
      <Flex gap>
        <FlexColumn style={{ flexBasis: RULE_GROUP_OPERATOR_WIDTH }} />
        <FlexColumn>
          <Button
            text="And"
            icon={Icon.Add}
            variant="secondary"
            isDisabled={isDisabled}
            onClick={() => {
              onChange({
                ...ruleGroup,
                args: [
                  ...ruleGroup.args,
                  {
                    name: RULE_DSL.ANY,
                    args: [
                      {
                        name: RULE_DSL.IS,
                        args: [{ id: "", type: "variable" }, ""],
                      },
                    ],
                  },
                ],
              });
            }}
          />
        </FlexColumn>
      </Flex>
    )}
  </FlexV>
);

export const RulesetEditor = ({
  ruleset,
  onChange,
  highlightInvalidFields,
}: {
  ruleset: RuleDefinition;
  onChange: (ruleset: RuleDefinition) => void;
  highlightInvalidFields?: boolean;
}) => {
  const { isReadOnly } = useReadOnly();
  const { variables } = useVariables();
  const { browser, channel, csatscore, device, language } =
    useFilterOptionsDict();

  // Rules support only a subset of variables
  const availableVariables =
    variables?.filter(
      (variable) =>
        variable.name === "channel" || // TODO: Remove this once "channel" is marked as meta on the backend
        variable.name === "chatter_id" || // TODO: Remove this once "chatter_id" is marked as meta on the backend
        variable.name === "end_user_id" || // TODO: Remove this once "end_user_id" is marked as meta on the backend
        (variable.scope !== "client_secret" &&
          variable.scope !== "oauth" &&
          variable.scope !== "local" &&
          variable.scope !== "builder_ab_tests" &&
          variable.scope !== "auto_capture"),
    ) || [];

  // Ruleset editor can only have a rule group at the root (ANY or ALL)
  if (!isRuleGroup(ruleset)) {
    return <>Unable to display the ruleset.</>;
  }

  return (
    <RuleGroupComponent
      ruleGroup={ruleset}
      onChange={(arg) => {
        if (isReadOnly) return;

        onChange(arg);
      }}
      variables={availableVariables}
      highlightInvalidFields={highlightInvalidFields}
      predefinedValues={{ browser, channel, csatscore, device, language }}
      isDisabled={isReadOnly}
    />
  );
};
