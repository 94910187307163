import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import { RulesetEditor as RulesetEditorV2 } from "components/Common/RulesetEditor/RulesetEditor";
import { RulesetEditor as RulesetEditorV1 } from "components/Common/RulesetEditor/RulesetEditorV1";
import { type RuleDefinition } from "services/rules";

interface Props {
  ruleset: RuleDefinition;
  onChange: (ruleset: RuleDefinition) => void;
  highlightInvalidFields?: boolean;
}

export function RulesetEditor(props: Props) {
  const flags = useFlags();

  if (flags["train-rules-editor-v2"]) {
    return <RulesetEditorV2 {...props} />;
  }

  return <RulesetEditorV1 {...props} />;
}
