// @ts-strict-ignore
import { toJpeg } from "html-to-image";
import { List, Map } from "immutable";
import { jsPDF as JsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { AnalyticsCount } from "resourceModels/AnalyticsCount";
import { Features } from "resourceModels/Client";
import { getResource } from "selectors/resources";
import { useClientLegacy } from "services/clientLegacy";
import { getTimezoneUTCOffset } from "services/timezones";

import { YEAR_OF_REPORT } from "../constants";

import {
  BACKGROUND_IMGS,
  BASE_REPORT_LINK,
  HEIGHT,
  METRIC_CONFIG,
  WIDTH,
  type analyticsColors,
} from "./constants";
import AdaLogo from "./images/Year-in-number-ada-logo-black.png";
import AdaBlack from "./images/ada-black.png";
import AdaWhite from "./images/ada-white.png";
import { jpegFilter } from "./services";
import * as S from "./styles";

interface Props {
  wrapUpReportCounts: List<AnalyticsCount>;
  hideConfetti?: boolean; // This is used for tests
}

interface BackgroundProps {
  steps: number;
  currentStep: number;
  line: number[];
  circle: number[];
  src: [string, number, number];
}

interface RenderedPagesState {
  pdf: JsPDF;
  rendered: number[];
}

interface RenderMetricsParms {
  label: keyof typeof METRIC_CONFIG;
  valueType: "array" | "number" | "links";
  value: List<string | number> | Map<string, string> | number | string | null;
  description: string[];
  color: analyticsColors | analyticsColors[];
  points?: string[];
}

const BackgroundGraphics = ({
  steps,
  currentStep,
  line,
  circle,
  src,
}: BackgroundProps) => (
  <>
    <S.DotsBackground step={currentStep}>
      {[...Array(steps).keys()].map((i) => (
        <svg
          width="600"
          height="4"
          viewBox="0 0 584 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={i}
        >
          <path
            d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M24 2C24 3.10457 23.1046 4 22 4C20.8954 4 20 3.10457 20 2C20 0.89543 20.8954 0 22 0C23.1046 0 24 0.89543 24 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M44 2C44 3.10457 43.1046 4 42 4C40.8954 4 40 3.10457 40 2C40 0.89543 40.8954 0 42 0C43.1046 0 44 0.89543 44 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M64 2C64 3.10457 63.1046 4 62 4C60.8954 4 60 3.10457 60 2C60 0.89543 60.8954 0 62 0C63.1046 0 64 0.89543 64 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M84 2C84 3.10457 83.1046 4 82 4C80.8954 4 80 3.10457 80 2C80 0.89543 80.8954 0 82 0C83.1046 0 84 0.89543 84 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M104 2C104 3.10457 103.105 4 102 4C100.895 4 100 3.10457 100 2C100 0.89543 100.895 0 102 0C103.105 0 104 0.89543 104 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M124 2C124 3.10457 123.105 4 122 4C120.895 4 120 3.10457 120 2C120 0.89543 120.895 0 122 0C123.105 0 124 0.89543 124 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M144 2C144 3.10457 143.105 4 142 4C140.895 4 140 3.10457 140 2C140 0.89543 140.895 0 142 0C143.105 0 144 0.89543 144 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M164 2C164 3.10457 163.105 4 162 4C160.895 4 160 3.10457 160 2C160 0.89543 160.895 0 162 0C163.105 0 164 0.89543 164 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M184 2C184 3.10457 183.105 4 182 4C180.895 4 180 3.10457 180 2C180 0.89543 180.895 0 182 0C183.105 0 184 0.89543 184 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M204 2C204 3.10457 203.105 4 202 4C200.895 4 200 3.10457 200 2C200 0.89543 200.895 0 202 0C203.105 0 204 0.89543 204 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M224 2C224 3.10457 223.105 4 222 4C220.895 4 220 3.10457 220 2C220 0.89543 220.895 0 222 0C223.105 0 224 0.89543 224 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M244 2C244 3.10457 243.105 4 242 4C240.895 4 240 3.10457 240 2C240 0.89543 240.895 0 242 0C243.105 0 244 0.89543 244 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M264 2C264 3.10457 263.105 4 262 4C260.895 4 260 3.10457 260 2C260 0.89543 260.895 0 262 0C263.105 0 264 0.89543 264 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M284 2C284 3.10457 283.105 4 282 4C280.895 4 280 3.10457 280 2C280 0.89543 280.895 0 282 0C283.105 0 284 0.89543 284 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M304 2C304 3.10457 303.105 4 302 4C300.895 4 300 3.10457 300 2C300 0.89543 300.895 0 302 0C303.105 0 304 0.89543 304 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M324 2C324 3.10457 323.105 4 322 4C320.895 4 320 3.10457 320 2C320 0.89543 320.895 0 322 0C323.105 0 324 0.89543 324 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M344 2C344 3.10457 343.105 4 342 4C340.895 4 340 3.10457 340 2C340 0.89543 340.895 0 342 0C343.105 0 344 0.89543 344 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M364 2C364 3.10457 363.105 4 362 4C360.895 4 360 3.10457 360 2C360 0.89543 360.895 0 362 0C363.105 0 364 0.89543 364 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M384 2C384 3.10457 383.105 4 382 4C380.895 4 380 3.10457 380 2C380 0.89543 380.895 0 382 0C383.105 0 384 0.89543 384 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M404 2C404 3.10457 403.105 4 402 4C400.895 4 400 3.10457 400 2C400 0.89543 400.895 0 402 0C403.105 0 404 0.89543 404 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M424 2C424 3.10457 423.105 4 422 4C420.895 4 420 3.10457 420 2C420 0.89543 420.895 0 422 0C423.105 0 424 0.89543 424 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M444 2C444 3.10457 443.105 4 442 4C440.895 4 440 3.10457 440 2C440 0.89543 440.895 0 442 0C443.105 0 444 0.89543 444 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M464 2C464 3.10457 463.105 4 462 4C460.895 4 460 3.10457 460 2C460 0.89543 460.895 0 462 0C463.105 0 464 0.89543 464 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M484 2C484 3.10457 483.105 4 482 4C480.895 4 480 3.10457 480 2C480 0.89543 480.895 0 482 0C483.105 0 484 0.89543 484 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M504 2C504 3.10457 503.105 4 502 4C500.895 4 500 3.10457 500 2C500 0.89543 500.895 0 502 0C503.105 0 504 0.89543 504 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M524 2C524 3.10457 523.105 4 522 4C520.895 4 520 3.10457 520 2C520 0.89543 520.895 0 522 0C523.105 0 524 0.89543 524 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M544 2C544 3.10457 543.105 4 542 4C540.895 4 540 3.10457 540 2C540 0.89543 540.895 0 542 0C543.105 0 544 0.89543 544 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M564 2C564 3.10457 563.105 4 562 4C560.895 4 560 3.10457 560 2C560 0.89543 560.895 0 562 0C563.105 0 564 0.89543 564 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M584 2C584 3.10457 583.105 4 582 4C580.895 4 580 3.10457 580 2C580 0.89543 580.895 0 582 0C583.105 0 584 0.89543 584 2Z"
            fill="#D9D9D9"
          />
        </svg>
      ))}
    </S.DotsBackground>
    <S.LineBackground left={line[0]} rotate={line[1]}>
      <svg
        width="2"
        height="1200"
        viewBox="0 0 2 1200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1200V0" stroke="#E5E7EB" strokeWidth={2} />
      </svg>
    </S.LineBackground>
    <S.CircleBackground left={circle[0]} top={circle[1]}>
      <svg
        width="107"
        height="107"
        viewBox="0 0 107 107"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="53.5"
          cy="53.5"
          r="53"
          stroke="#EDEFF2"
          fill="none"
          strokeWidth={2}
        />
      </svg>
    </S.CircleBackground>
    {/* Render all images to prevent pop-in from changing source on single image tag */}
    {Object.entries(BACKGROUND_IMGS).map(([k, v]) => (
      <S.ImageBackground
        key={k}
        src={v}
        left={src[1]}
        top={src[2]}
        isVisible={k === src[0]}
      />
    ))}
  </>
);

export const AnalyticsYearEndWrapUpReportModal = ({
  wrapUpReportCounts,
  hideConfetti,
}: Props) => {
  const dispatch = useDispatch();
  const { client } = useClientLegacy();
  const user = useSelector((state) => getResource(state, "user"));

  const [showConfetti, setShowConfetti] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [metricsToShow, setMetricsToShow] = useState<AnalyticsCount[]>([]);
  const [renderedPages, setRenderedPages] = useState<RenderedPagesState>({
    pdf: new JsPDF(),
    rendered: [],
  });
  const [renderingSlide, setRenderingSlide] = useState<boolean>(true);
  const [fadeIn, setFadeIn] = useState<boolean>(true);

  const rootEle = useRef<HTMLDivElement>(null);
  const isFirstPage = currentStep === -1;
  const isLastPage = currentStep === metricsToShow.length;

  useEffect(() => {
    if (isFirstPage || isLastPage) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }

    setFadeIn(true);
    setTimeout(() => setFadeIn(false), 1000);
  }, [currentStep, isFirstPage, isLastPage]);

  useEffect(() => {
    const metricsData: AnalyticsCount[] = [];
    const reportLinks: { [key: string]: string } = {
      "/analytics/reports/conversations_breakdown": "Engaged Conversations",
      "/analytics/reports/total_answers": "Total Answers",
      "/conversations/topics": "Conversation Topics",
      "/analytics/reports/answers_performance": "Thumbs Up",
    };

    wrapUpReportCounts.forEach((data) => {
      const { label, value } = data;

      if (label === "topics_created" && (value as number) <= 2) {
        if (!client.hasFeature(Features.ML_CONVERSATIONS_TOPICS_TEMP)) {
          delete reportLinks["/conversations/topics"];
        }

        metricsData.push(
          new AnalyticsCount({
            id: "topics_created_placeholder",
            label: "topics_created_placeholder",
            value: -1,
            valueType: "number",
          }),
        );
      } else if (
        label in METRIC_CONFIG &&
        "minimumValue" in METRIC_CONFIG[label] &&
        (value as number) >= (METRIC_CONFIG[label]?.minimumValue as number)
      ) {
        metricsData.push(data);
      }
    });

    setMetricsToShow([
      new AnalyticsCount({
        id: "disclaimer",
        label: "disclaimer",
        value: List([
          "January 1 - December 11, 2022",
          getTimezoneUTCOffset(user?.timeZone || "UTC"),
        ]),
        valueType: "array",
      }),
      ...metricsData,
      new AnalyticsCount({
        id: "report_links",
        label: "report_links",
        value: Map(reportLinks),
        valueType: "links",
      }),
    ]);

    const pdf = new JsPDF({
      unit: "px",
      format: [WIDTH, HEIGHT * (metricsData.length + 3)], // Add page for intro, disclaimer, and outro
    });
    setRenderedPages({ ...renderedPages, pdf });
  }, []);

  useEffect(() => {
    if (
      metricsToShow.length > 0 &&
      currentStep !== metricsToShow.length - 1 && // Skip report links page
      !renderedPages.rendered.includes(currentStep) &&
      rootEle.current
    ) {
      setRenderingSlide(true);
      setTimeout(() => {
        toJpeg(rootEle.current as HTMLElement, {
          filter: jpegFilter,
          canvasHeight: HEIGHT,
          canvasWidth: WIDTH,
        }).then((dataUrl) => {
          const newPdf = renderedPages.pdf;

          newPdf.addImage(
            dataUrl,
            "JPEG",
            0,
            HEIGHT * renderedPages.rendered.length,
            WIDTH,
            HEIGHT,
          );

          setRenderedPages({
            pdf: newPdf,
            rendered: [...renderedPages.rendered, currentStep],
          });
          setRenderingSlide(false);
        });
      }, 1500);
    }
  }, [currentStep, metricsToShow, renderedPages.pdf, renderedPages.rendered]);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleReportLink = (reportId: string) => () => {
    window.open(BASE_REPORT_LINK.replace("{url}", reportId), "_blank");
  };

  const handleDownload = () => {
    renderedPages.pdf.save(`Ada Year In Numbers (${YEAR_OF_REPORT}).pdf`);
    dispatch(closeModalAction());
  };

  const renderFirstPage = () => (
    <S.DataWrapper>
      <S.ChatBubble bg="plum200">Hi {user?.name} 👋</S.ChatBubble>
      <S.DataBackground position="center">
        <S.DataContent bottom fadeIn={fadeIn}>
          As we wrap up {YEAR_OF_REPORT}, let&apos;s take a look at how your bot
          performed over the last year!
        </S.DataContent>
      </S.DataBackground>
      <S.AdaLogo>
        <S.AdaLogoImg src={AdaWhite} alt="ada logo" />
      </S.AdaLogo>
    </S.DataWrapper>
  );

  const renderLastPage = () => (
    <S.LastPageContent>
      Thanks, {user?.name}, for being a leader in customer experience.
      <S.Spacer />
      We can&apos;t wait to see what we can achieve together in{" "}
      {YEAR_OF_REPORT + 1}! 🎉
      <S.Spacer />
      <S.AdaLogoImg src={AdaBlack} alt="ada logo" />
    </S.LastPageContent>
  );

  const renderMetrics = ({
    valueType,
    value,
    description,
    color,
    points,
  }: RenderMetricsParms) => {
    if (valueType === "array" && points) {
      return (
        <>
          {description.map((str) => str)}

          {(value as List<number>).map((val, i) => (
            <S.MetricString
              metricColor={color[i] as analyticsColors}
              key={`label-${val}`}
            >
              {points[i].replace("{value}", val.toLocaleString())}
            </S.MetricString>
          ))}
        </>
      );
    }

    if (valueType === "links") {
      const links = (value as Map<string, string>).toObject();

      return (
        <>
          {description.map((str) => str)}
          {Object.entries(links).map(([reportId, linkStr]) => (
            <S.ReportLinkWrapper>
              💡{" "}
              <S.ReportLink key={reportId} onClick={handleReportLink(reportId)}>
                {linkStr}
                <SvgIcon icon="ArrowNE" height={20} />
              </S.ReportLink>
            </S.ReportLinkWrapper>
          ))}
        </>
      );
    }

    return (
      <>
        {description.map((str) => {
          if (str.includes("{value}")) {
            return (
              <S.MetricString metricColor={color as analyticsColors} key={str}>
                {str.replace(
                  "{value}",
                  ((value as number) > 1
                    ? Math.round(value as number)
                    : (value as number)
                  ).toLocaleString(),
                )}
              </S.MetricString>
            );
          }

          return str;
        })}
      </>
    );
  };

  const renderDataPages = () => {
    if (isFirstPage) {
      return renderFirstPage();
    }

    if (isLastPage) {
      return renderLastPage();
    }

    const { label, value, valueType } = metricsToShow[currentStep];
    const {
      title,
      description,
      points,
      note,
      url,
      chat,
      data,
      color,
      background,
    } = METRIC_CONFIG[label];
    const showNote =
      note &&
      ((label === "topics_created_placeholder" &&
        client.hasFeature(Features.ML_CONVERSATIONS_TOPICS_TEMP)) ||
        label !== "topics_created_placeholder");

    return (
      <>
        <BackgroundGraphics
          steps={metricsToShow.length}
          currentStep={currentStep}
          {...background}
        />
        <S.DataWrapper>
          <S.ChatBubble {...chat}>{title}</S.ChatBubble>
          <S.DataBackground position={data.position}>
            <S.DataContent bottom={data.bottom} fadeIn={fadeIn}>
              {renderMetrics({
                label,
                valueType,
                value,
                description,
                color,
                points,
              })}
            </S.DataContent>
            {showNote && (
              <S.MetricNote fadeIn={fadeIn}>
                {note}{" "}
                {url && (
                  <a href={url} target="_blank" rel="noreferrer">
                    (Source)
                  </a>
                )}
              </S.MetricNote>
            )}
          </S.DataBackground>
        </S.DataWrapper>
      </>
    );
  };

  let nextButtonLabel = "Let's go!";

  if (!isFirstPage && !isLastPage) {
    const { label } = metricsToShow[currentStep];
    nextButtonLabel = METRIC_CONFIG[label].nextButtonText;
  }

  return (
    <S.Root>
      <S.Container>
        <S.Header>
          <S.AdaNamedLogo src={AdaLogo} />
        </S.Header>
        <S.ContentWrapper dark={isLastPage} ref={rootEle}>
          {renderDataPages()}
        </S.ContentWrapper>
        <S.NavButtons>
          {isFirstPage || isLastPage ? (
            <div /> // Render empty div to push next button to the right
          ) : (
            <Button title="back" text="Back" onClick={handlePrevStep} light />
          )}
          {isLastPage ? (
            <>
              <Button
                title="download"
                text="Download"
                onClick={handleDownload}
                icon="Download"
              />
              <div />
            </>
          ) : (
            <Button
              title={nextButtonLabel}
              text={nextButtonLabel}
              onClick={handleNextStep}
              disabled={renderingSlide}
            />
          )}
        </S.NavButtons>
      </S.Container>
      {!hideConfetti && (
        <Confetti
          numberOfPieces={400}
          initialVelocityY={10}
          colors={["#76ADC7", "#E7BB56", "#9576DA"]}
          recycle={showConfetti}
        />
      )}
    </S.Root>
  );
};

AnalyticsYearEndWrapUpReportModal.closeColor = "#000000";
