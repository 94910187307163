// TODO FEED-12 FEED-20: Fix strict mode errors
// @ts-strict-ignore
import classNames from "classnames";
import React, { type ReactElement } from "react";
import ReactDOM from "react-dom";

import SvgIcon from "components/Common/SvgIcon";
import colors from "stylesheets/utilities/colors.scss";

import "./styles.scss";

function getScrollParent(element: HTMLElement | null): HTMLElement | null {
  const isElement = element instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(element).overflowY;
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

  if (!element) {
    return null;
  }

  if (isScrollable && element.scrollHeight >= element.clientHeight) {
    return element;
  }

  return getScrollParent(element.parentElement) || document.body;
}

interface Clearance {
  right: { mid: unknown; full: unknown };
  left: { mid: unknown; full: unknown };
  top: { mid: unknown; full: unknown; part: unknown };
  bottom: { mid: unknown; full: unknown; part: unknown };
}

function getClearance(
  tooltipBounds: DOMRect | ClientRect,
  blurbBounds: DOMRect | ClientRect,
  blurbMargin: number,
  scrollParent: HTMLElement,
): Clearance {
  const scrollParentBounds = scrollParent.getBoundingClientRect();

  const spaceToEdge = {
    top: tooltipBounds.top - scrollParentBounds.top,
    bottom: scrollParentBounds.bottom - tooltipBounds.bottom,
    left: tooltipBounds.left - scrollParentBounds.left,
    right: scrollParentBounds.right - tooltipBounds.right,
  };

  return {
    right: {
      mid:
        spaceToEdge.right >=
        (blurbBounds.width - tooltipBounds.width - blurbMargin) / 2,
      full: spaceToEdge.right >= blurbBounds.width + blurbMargin,
    },
    left: {
      mid:
        spaceToEdge.left >=
        (blurbBounds.width - tooltipBounds.width - blurbMargin) / 2,
      full: spaceToEdge.left >= blurbBounds.width + blurbMargin,
    },
    top: {
      mid:
        spaceToEdge.top >=
        (blurbBounds.height - tooltipBounds.height - blurbMargin) / 2,
      full: spaceToEdge.top >= blurbBounds.height + blurbMargin,
      part:
        spaceToEdge.top >=
        blurbBounds.height - tooltipBounds.height - blurbMargin,
    },
    bottom: {
      mid:
        spaceToEdge.bottom >=
        (blurbBounds.height - tooltipBounds.height - blurbMargin) / 2,
      full: spaceToEdge.bottom >= blurbBounds.height + blurbMargin,
      part:
        spaceToEdge.bottom >=
        blurbBounds.height - tooltipBounds.height - blurbMargin,
    },
  };
}

const BLOCK_NAME = "Tooltip";

export interface InfoTooltipProps {
  above?: boolean;
  maxWidth?: number;
  opened?: boolean;
  openOnHover?: boolean;
  iconHeight?: number;
  iconClicked?: string;
  iconDefault?: string;
  container?: HTMLElement;
  tooltipImgPath?: string;
  tooltipImgAltText?: string;
  inModal?: boolean;
  link?: {
    href: string;
    title: string;
  } | null;
  customClassName?: string;
  className?: string; // makes it possible to extend with styled-components
  blurb?: string | React.ReactNode;
  absolute?: boolean;
  tabIndex?: number;
  children?: string | React.ReactNode;
}

interface InfoTooltipState {
  clicked: boolean;
  position: Record<string, unknown>;
  relativeToContainer: Record<string, unknown>;
  dimple: string | null;
  type: unknown;
}

export class InfoTooltip extends React.Component<
  InfoTooltipProps,
  InfoTooltipState
> {
  defaultPosition = {
    left: 0,
    top: 0,
    maxWidth: this.props.maxWidth,
  };

  state: InfoTooltipState = {
    clicked: false,
    position: this.defaultPosition,
    relativeToContainer: this.defaultPosition,
    dimple: null,
    type: null,
  };

  blurbRef = React.createRef<HTMLDivElement>();
  tooltipRef = React.createRef<HTMLDivElement>();
  tooltipImageRef = React.createRef<HTMLImageElement>();

  handleClickBound = this.handleClick.bind(this);
  handleLinkClickBound = this.handleLinkClick.bind(this);
  handleClickOutsideBound = this.handleClickOutside.bind(this);
  onKeyPressBound = this.onKeyPress.bind(this);
  onKeyPressOutsideBound = this.onKeyPressOutside.bind(this);
  handleContainerScrollBound = this.handleContainerScroll.bind(this);

  hideTimout: NodeJS.Timeout;

  dimple = (
    <svg width="12" height="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.175 20.651C3.058 24.349 0 28.132 0 32V0c0 3.821 2.985 7.368 8.955 10.642 2.878 1.578 3.896 5.127 2.272 7.926a5.893 5.893 0 0 1-2.052 2.083z"
        fill={colors.colorBlack}
        fillRule="evenodd"
      />
    </svg>
  );

  static defaultProps: Partial<InfoTooltipProps> = {
    customClassName: "",
    link: null,
  };

  componentDidMount(): void {
    this.updatePositioning();
  }

  componentDidUpdate(
    prevProps: InfoTooltipProps,
    prevState: InfoTooltipState,
  ): void {
    const { clicked } = this.state;
    const { container, tooltipImgPath, opened } = this.props;

    if (prevState.clicked !== clicked) {
      if (clicked) {
        document.addEventListener("click", this.handleClickOutsideBound, true);
        document.addEventListener("keydown", this.onKeyPressOutsideBound, true);

        if (container) {
          // Prevent adding a listener multiple times
          container.removeEventListener(
            "scroll",
            this.handleContainerScrollBound,
            true,
          );
          container.addEventListener(
            "scroll",
            this.handleContainerScrollBound,
            true,
          );
        }
      } else {
        this.removeEventListeners();
      }

      if (this.tooltipImageRef.current) {
        if (typeof tooltipImgPath === "string") {
          this.tooltipImageRef.current.src = tooltipImgPath;
        }
      }
    }

    if (opened && !prevProps.opened) {
      this.updatePositioning();
    }
  }

  componentWillUnmount(): void {
    this.removeEventListeners();
  }

  onKeyPress(e: React.KeyboardEvent): void {
    if (e.key === "enter") {
      this.handleClick(e);
    } else if (e.key === "Escape") {
      this.setState({ clicked: false });
    }
  }

  onKeyPressOutside(e: React.KeyboardEvent): void {
    return this.onKeyPress(e);
  }

  getBlurbPosition(): {
    type: unknown;
    position: {
      top: unknown;
      left: unknown;
      right: unknown;
      bottom: unknown;
      maxWidth: number;
    };
    relativeToContainer: Record<string, unknown>;
    dimple: string;
  } {
    const { above, maxWidth } = this.props;

    const blurbElement = this.blurbRef.current;
    const blurbBounds = blurbElement.getBoundingClientRect();

    const tooltipElement = this.tooltipRef.current;
    const tooltipBounds = tooltipElement.getBoundingClientRect();

    const blurbMargin = 16;

    // 16 is the padding between the tooltip and the blurb
    const tooltipWidth = tooltipBounds.width + blurbMargin;
    const tooltipHeight = tooltipBounds.height + blurbMargin;

    const scrollParent = getScrollParent(tooltipElement);

    const clearance = getClearance(
      tooltipBounds,
      blurbBounds,
      blurbMargin,
      scrollParent,
    );

    let type;
    let top: number | null | undefined;
    let bottom: number | null | undefined;
    let left: number | null | undefined;
    let right: number | null | undefined;
    // which way the dimple points
    let dimple;

    if (above) {
      type = "BC";
      bottom = tooltipHeight;
      left = -(blurbBounds.width - tooltipBounds.width) / 2;
      dimple = "down";
    } else if (
      clearance.bottom.full &&
      clearance.left.mid &&
      clearance.right.mid
    ) {
      type = "TC";
      top = tooltipHeight;
      left = -(blurbBounds.width - tooltipBounds.width) / 2;
      dimple = "up";
    } else if (
      (clearance.top.full && clearance.left.mid && clearance.right.mid) ||
      (clearance.top.mid && (clearance.left.mid || clearance.right.mid))
    ) {
      type = "BC";
      bottom = tooltipHeight - 4;
      left = -(blurbBounds.width - tooltipBounds.width) / 2;
      dimple = "down";
    } else if (
      clearance.right.full &&
      clearance.bottom.mid &&
      clearance.top.mid
    ) {
      type = "ML";
      left = tooltipWidth;
      top = -(blurbBounds.height - tooltipBounds.height) / 2;
      dimple = "left";
    } else if (
      clearance.left.full &&
      clearance.bottom.mid &&
      clearance.top.mid
    ) {
      type = "MR";
      right = tooltipWidth;
      top = -(blurbBounds.height - tooltipBounds.height) / 2;
      dimple = "right";
    } else if (clearance.right.full && clearance.bottom.part) {
      type = "TL";
      left = tooltipWidth + blurbMargin / 2;
      top = -5;
      dimple = "left";
    } else if (clearance.right.full && clearance.top.part) {
      type = "BL";
      left = tooltipWidth;
      bottom = -14;
      dimple = "left";
    } else if (clearance.left.full && clearance.bottom.part) {
      type = "TR";
      right = tooltipWidth;
      top = -12;
      dimple = "right";
    } else if (clearance.left.full && clearance.top.part) {
      type = "BR";
      right = tooltipWidth;
      bottom = -16;
      dimple = "right";
    }

    const relativeToContainer = {
      top: top !== null && top !== undefined ? tooltipBounds.top + top : null,
      left:
        left !== null && left !== undefined ? tooltipBounds.left + left : null,
      right:
        right !== null && right !== undefined
          ? window.innerWidth - tooltipBounds.right + right
          : null,
      bottom:
        bottom !== null && bottom !== undefined
          ? window.innerHeight - tooltipBounds.bottom + bottom
          : null,
    };

    return {
      type,
      position: {
        top,
        right,
        bottom,
        left,
        maxWidth,
      },
      relativeToContainer,
      dimple,
    };
  }

  show = () => {
    const positioning = this.getBlurbPosition();

    this.setState({
      clicked: true,
      position: positioning.position,
      dimple: positioning.dimple,
      type: positioning.type,
      relativeToContainer: positioning.relativeToContainer,
    });
  };

  hide = () => {
    this.setState({ clicked: false });
  };

  onMouseEnter = () => {
    clearTimeout(this.hideTimout);

    this.show();
  };

  onMouseLeave = () => {
    clearTimeout(this.hideTimout);

    this.hideTimout = setTimeout(() => this.hide(), 200);
  };

  removeEventListeners(): void {
    const { container } = this.props;

    document.removeEventListener("click", this.handleClickOutsideBound, true);
    document.removeEventListener("keydown", this.onKeyPressOutsideBound, true);

    if (container) {
      container.removeEventListener(
        "scroll",
        this.handleContainerScrollBound,
        true,
      );
    }
  }

  handleClick(e: Event | React.KeyboardEvent | React.MouseEvent): void {
    e.stopPropagation();
    const positioning = this.getBlurbPosition();

    clearTimeout(this.hideTimout);

    this.setState((prevState) => ({
      clicked: !prevState.clicked,
      position: prevState.clicked ? prevState.position : positioning.position,
      dimple: positioning.dimple,
      type: positioning.type,
      relativeToContainer: positioning.relativeToContainer,
    }));
  }

  /**
   * @param {Event} e
   */
  handleClickOutside(e: React.MouseEvent): void {
    const { openOnHover } = this.props;

    if (!openOnHover && !this.tooltipRef.current.contains(e.currentTarget)) {
      e.preventDefault();
      this.setState({ clicked: false });
    }
  }

  handleLinkClick(e: Event): void {
    const { link } = this.props;

    e.preventDefault();
    window.open(link.href, "");
  }

  /** Updates state on `container` scroll event */
  handleContainerScroll(): void {
    const positioning = this.getBlurbPosition();

    this.setState({
      dimple: positioning.dimple,
      relativeToContainer: positioning.relativeToContainer,
    });
  }

  /**
   * Methods
   */

  updatePositioning(): void {
    const positioning = this.getBlurbPosition();

    this.setState({
      position: positioning.position,
      dimple: positioning.dimple,
      type: positioning.type,
      relativeToContainer: positioning.relativeToContainer,
    });
  }

  renderTooltip(isOpen: boolean): ReactElement {
    const {
      customClassName,
      link,
      blurb,
      tooltipImgPath,
      tooltipImgAltText,
      absolute,
      maxWidth,
    } = this.props;

    const { position, relativeToContainer, dimple } = this.state;

    const relativeToContainerWithMaxWidth = {
      ...relativeToContainer,
      maxWidth,
      bottom: isOpen
        ? (relativeToContainer.bottom as string | number)
        : undefined,
    };
    const positionWithMaxWidth = {
      ...position,
      maxWidth,
    };

    return (
      <div
        className={classNames("Tooltip__container", {
          "Tooltip__container--clicked": isOpen,
          [customClassName]: customClassName,
        })}
        style={
          absolute ? relativeToContainerWithMaxWidth : positionWithMaxWidth
        }
        ref={this.blurbRef}
      >
        <div className={`${BLOCK_NAME}__animated-container`}>
          <div
            className={classNames(`${BLOCK_NAME}__dimple`, {
              [`${BLOCK_NAME}__dimple--${dimple}`]: dimple,
            })}
          >
            {this.dimple}
          </div>
          <div
            className={classNames(`${BLOCK_NAME}__blurb`, {
              [`${BLOCK_NAME}__blurb--${dimple}`]: dimple,
            })}
          >
            <div className={`${BLOCK_NAME}__blurb-content`}>
              {Boolean(tooltipImgPath) && (
                <div className={`${BLOCK_NAME}__blurb-image-container`}>
                  <img
                    className={`${BLOCK_NAME}__blurb-image`}
                    ref={this.tooltipImageRef}
                    src={tooltipImgPath}
                    alt={tooltipImgAltText}
                  />
                </div>
              )}

              {Boolean(blurb) && (
                <div className={`${BLOCK_NAME}__blurb-text`}>{blurb}</div>
              )}

              {Boolean(link && link.href && link.title) && (
                <a
                  className={`${BLOCK_NAME}__link`}
                  href={link.href}
                  onClick={this.handleLinkClickBound}
                >
                  {link.title}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUsingPortal(isOpen: boolean): ReactElement {
    const { inModal } = this.props;

    return inModal
      ? ReactDOM.createPortal(
          this.renderTooltip(isOpen),
          document.getElementById("modal"),
        )
      : ReactDOM.createPortal(this.renderTooltip(isOpen), document.body);
  }

  render(): ReactElement {
    const {
      openOnHover,
      blurb,
      link,
      absolute,
      iconHeight,
      iconClicked,
      iconDefault,
      opened,
      customClassName,
      className,
      tabIndex,
      children,
    } = this.props;

    const { type, clicked } = this.state;

    const isOpen = clicked || opened;
    const controlled = typeof opened !== "undefined";
    const isNoIcon = iconDefault === "none";

    let TooltipIcon;

    if (children) {
      TooltipIcon = children;
    } else if (isNoIcon) {
      TooltipIcon = null;
    } else if (isOpen && iconClicked) {
      TooltipIcon = <SvgIcon icon={iconClicked} height={iconHeight} />;
    } else {
      TooltipIcon = <SvgIcon icon={iconDefault} height={iconHeight} />;
    }

    return (
      <div
        ref={this.tooltipRef}
        className={classNames("Tooltip", {
          "Tooltip--clicked": isOpen,
          "Tooltip--no-icon": isNoIcon,
          "Tooltip--children": children,
          [`Tooltip--${type}`]: type,
          [customClassName]: customClassName,
          [className]: className,
        })}
        onMouseEnter={openOnHover ? this.onMouseEnter : null}
        onMouseLeave={openOnHover ? this.onMouseLeave : null}
      >
        <div
          className={classNames("Tooltip__icon", {
            "Tooltip__icon--no-icon": isNoIcon,
          })}
          onClick={!openOnHover && !controlled ? this.handleClickBound : null}
          onKeyPress={this.onKeyPressBound}
          role="button"
          tabIndex={tabIndex}
        >
          {TooltipIcon}
        </div>
        {(blurb || link) && absolute
          ? this.renderUsingPortal(isOpen)
          : this.renderTooltip(isOpen)}
      </div>
    );
  }
}
