// TODO FEED-12 FEED-18: Fix strict mode errors
// @ts-strict-ignore
import { type AxiosError } from "axios";
import Immutable from "immutable";

import { createAlert } from "actions/alerts";
import { type BotContentRequest } from "actions/responses";
import {
  type CallApiAction,
  type Dispatch,
  type ThunkAction,
} from "actions/types";
import {
  MESSAGING_MODALITY,
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import {
  BUILDER_AB_TESTS_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  LOCAL_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
} from "constants/variables";
import {
  type ConditionalMessageRecord,
  type HTTPMessageRecord,
  type MessageRecord,
  type ScheduledBlockRecord,
} from "reducers/responses/messageRecords";
import { type State } from "reducers/types";
import {
  constructResponseReferences,
  isDirty,
  isSaved,
} from "reducers/variables/helpers";
import { adaApiRequest } from "services/api";
import { selectClient } from "services/client/selectors";
import { keyConverter } from "services/key-converter";
import { type LanguageCode } from "services/language";
import { type KeyPath } from "services/responses";
import {
  type VariableLegacy as Variable,
  type VariableRecord,
} from "services/variables";

/*
  TODO:
    - some of these functions dispatch the action,
      while some just create them; make it consistent
*/

interface GetAllVariablesArgs {
  responseId: string;
  includeInactive?: boolean;
}

export const getAllVariables = ({
  responseId,
  includeInactive = false,
}: GetAllVariablesArgs) =>
  responseId && {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      args: {
        responseId,
      },
      endpoint: `/variables/?scope=all&response_id=${responseId}&include_inactive=${includeInactive}`,
      types: [
        "GET_ALL_VARIABLES_REQUEST",
        "GET_ALL_VARIABLES_SUCCESS",
        "GET_ALL_VARIABLES_FAILURE",
      ],
    },
  };

/**
 */
export const getAllClientVariables = () => ({
  // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
  CALL_API: {
    method: "get",
    endpoint: "/variables/",
    types: [
      "GET_ALL_CLIENT_VARIABLES_REQUEST",
      "GET_ALL_CLIENT_VARIABLES_SUCCESS",
      "GET_ALL_CLIENT_VARIABLES_FAILURE",
    ],
  },
});

export const getAllClientVariablesV2 = () => ({
  // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
  CALL_API: {
    method: "get",
    endpoint: "/v2/variable",
    types: [
      "GET_ALL_CLIENT_VARIABLES_REQUEST",
      "GET_ALL_CLIENT_VARIABLES_SUCCESS_V2",
      "GET_ALL_CLIENT_VARIABLES_FAILURE",
    ],
  },
});

export const createVariable = (variable: VariableRecord, responseId: string) =>
  ({
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      payload: keyConverter(variable.toJS(), "underscore"),
      args: { variable, responseId },
      endpoint: "/variables/",
      types: [
        "CREATE_VARIABLE_REQUEST",
        "CREATE_VARIABLE_SUCCESS",
        "CREATE_VARIABLE_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: "error",
        },
      ],
    },
  } as CallApiAction);

export const updateVariable = (variable: VariableRecord, responseId: string) =>
  ({
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: `/variables/${variable.id}/`,
      args: {
        variable,
        responseId,
      },
      payload: keyConverter(variable.toJS(), "underscore"),
      types: [
        "UPDATE_VARIABLE_REQUEST",
        "UPDATE_VARIABLE_SUCCESS",
        "UPDATE_VARIABLE_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: "error",
        },
      ],
    },
  } as CallApiAction);

export function deleteVariable(
  variable: VariableRecord | { id: string },
): ThunkAction {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_VARIABLE_REQUEST",
      variable,
    });

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/v2/variable/${variable.id}`,
        }),
      );
      dispatch({
        type: "GET_ALL_CLIENT_VARIABLES_SUCCESS_V2",
        response,
      });
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      dispatch({ type: "DELETE_VARIABLE_FAILURE" });
      dispatch(
        createAlert({
          message: error.response?.data.message || "Failed to delete variable",
          alertType: "error",
        }),
      );
    }
  };
}

function saveVariables(
  variables:
    | Immutable.Collection<number, VariableRecord>
    | Immutable.List<VariableRecord>,
  responseId: string,
): ThunkAction {
  return (dispatch) =>
    variables.map((variable) => dispatch(createVariable(variable, responseId)));
}

function updateVariables(
  variables:
    | Immutable.Collection<number, VariableRecord>
    | Immutable.List<VariableRecord>,
  responseId: string,
): ThunkAction {
  return (dispatch) =>
    variables.map((variable) => dispatch(updateVariable(variable, responseId)));
}

const getExistingGlobalVar = (
  state: State,
  variable: VariableRecord | Partial<Variable>,
) =>
  state.variables
    .get(GLOBAL_VARIABLE_SCOPE)
    .find(
      (global: VariableRecord) =>
        global.get("name") === variable.name && global.get("isValid"),
    );

/**
 * Returns existing local variable with same name if it exists
 */
const getExistingLocalVar = (
  state: State,
  variable: VariableRecord | Partial<Variable>,
  responseId: string,
) => {
  if (!state.variables.get(LOCAL_VARIABLE_SCOPE).has(responseId)) {
    return null;
  }

  return state.variables
    .getIn([LOCAL_VARIABLE_SCOPE, responseId])
    .find(
      (local: VariableRecord) =>
        local.get("name") === variable.name && local.get("isValid"),
    );
};

/**
 * Returns existing abtests variable with same name if it exists
 */
const getExistingABTestsVar = (
  state: State,
  variable: VariableRecord | Partial<Variable>,
) =>
  state.variables
    .get(BUILDER_AB_TESTS_VARIABLE_SCOPE)
    .find(
      (currentVar: VariableRecord) =>
        currentVar.get("name") === variable.name && currentVar.get("isValid"),
    );

/**
 * Get the corresponding local or global or abtests variable if it exists
 */
const getExistingVariable = (
  state: State,
  variable: VariableRecord | Partial<Variable>,
  responseId: string,
) => {
  switch (variable.scope) {
    case GLOBAL_VARIABLE_SCOPE:
      return getExistingGlobalVar(state, variable);
    case BUILDER_AB_TESTS_VARIABLE_SCOPE:
      return getExistingABTestsVar(state, variable);
    default:
      return getExistingLocalVar(state, variable, responseId);
  }
};

interface CreateUnsavedVariableArgs {
  variable: VariableRecord | Partial<Variable>;
  responseId: string | null;
}

export function createUnsavedVariable({
  variable,
  responseId,
}: CreateUnsavedVariableArgs): ThunkAction<VariableRecord> {
  return (dispatch, getState) => {
    const state = getState();
    let existingVar;

    if (responseId) {
      existingVar = getExistingVariable(state, variable, responseId);

      if (existingVar) {
        existingVar = existingVar.merge({
          key: variable.key,
        });
      }
    }

    dispatch({
      type: "CREATE_UNSAVED_VARIABLE",
      variable,
      responseId,
      existingVar,
    });

    return existingVar || variable;
  };
}

interface SetDropdownVariableArgs {
  variable: VariableRecord;
  newVariable: VariableRecord;
  responseId: string;
}

// This is to update the old and new variables' reference counts
export const setDropdownVariable = ({
  variable,
  newVariable,
  responseId,
}: SetDropdownVariableArgs) => ({
  type: "SET_DROPDOWN_VARIABLE",
  variable,
  newVariable,
  responseId,
});

interface UpdateVariableStateArgs {
  variable: VariableRecord;
  newVariable: VariableRecord;
  responseId: string;
}

/**
 * Action to update properties of `variable` to that of `newVariable` within state
 */
export function updateVariableState({
  variable,
  newVariable,
  responseId,
}: UpdateVariableStateArgs): ThunkAction {
  return (dispatch) => {
    let updatedVar = newVariable;

    if (!newVariable.equals(variable)) {
      updatedVar = updatedVar.merge({
        modified: true,
        isValid: Boolean(updatedVar.get("name")),
      });
      dispatch({
        type: "UPDATE_VARIABLE",
        variable,
        updatedVar,
        responseId,
      });
    }
  };
}

export function deleteVariableState(variableId: string, responseId: string) {
  return {
    type: "DELETE_VARIABLE",
    variableId,
    responseId,
  };
}

export const getAuths = () => ({
  // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
  CALL_API: {
    method: "get",
    endpoint: "/auths/",
    types: ["GET_AUTHS_REQUEST", "GET_AUTHS_SUCCESS", "GET_AUTHS_FAILURE"],
  },
});

const groupVariablesByAction = (
  allVariables: Immutable.List<VariableRecord>,
  responseId: string,
) =>
  allVariables
    .map((variable) => {
      if (
        !variable.responseReferenceCounts.has(responseId) ||
        (variable.getIn(["responseReferenceCounts", responseId, "unsaved"]) ===
          0 &&
          !variable.get("modified"))
      ) {
        /*
            This variable has no relation to this responseId or
            there are no unsaved changes that need to be made for this responseId
          */
        return { type: "other", variable };
      }

      if (!isSaved(variable)) {
        return { type: "create", variable };
      }

      if (isDirty(variable, responseId)) {
        return { type: "update", variable };
      }

      return { type: "other", variable };
    })
    .groupBy((variableGroup) => variableGroup.type)
    .map((variables) => variables.map((v) => v.variable));

const getAllVariablesFlat = (state: State, responseId: string) =>
  Immutable.List()
    .concat(
      state.variables.getIn([LOCAL_VARIABLE_SCOPE, responseId]),
      state.variables.get(GLOBAL_VARIABLE_SCOPE),
      state.variables.get(OAUTH_VARIABLE_SCOPE),
      state.variables.get(SENSITIVE_VARIABLE_SCOPE),
      state.variables.get(BUILDER_AB_TESTS_VARIABLE_SCOPE),
    )
    .filter(Boolean);

/**
 * Modifies variables appropriately before saving to API
 */
function prepareVariables(
  allVariables: Immutable.List<VariableRecord>,
  responseId: string,
) {
  return allVariables.map((variable) => {
    const responseReferences = constructResponseReferences(
      variable,
      responseId,
    );

    return variable.merge({
      responseReferences,
    });
  });
}

export function getVariablesPromises(
  responseId: string,
  dispatch: Dispatch,
  getState: () => State,
) {
  const state = getState();

  const client = selectClient(state);

  if (!client?.features.personalization) {
    return Promise.resolve();
  }

  const emptyList = Immutable.List();

  let allVariables = getAllVariablesFlat(state, responseId);

  // TODO: investigate whether this is still needed - I'm pretty sure api doesn't rely on frontend variable reference counts
  allVariables = prepareVariables(allVariables, responseId);

  const groupedVariables = groupVariablesByAction(allVariables, responseId);

  const variablesToUpdate = groupedVariables
    .get("update", emptyList)
    .map((variable) => {
      const responseReferences = variable.responseReferences.filter(
        (reference: string) => reference,
      );

      return variable.set("responseReferences", responseReferences);
    });

  // Wait for variables to save, delete, and update before publishing response changes
  let variablesPromises = [
    dispatch(
      saveVariables(groupedVariables.get("create", emptyList), responseId),
    ),
    dispatch(updateVariables(variablesToUpdate, responseId)),
  ];
  variablesPromises = variablesPromises
    .reduce((acc, list) => acc.concat(list), Immutable.List())
    .toJS();

  return Promise.all(variablesPromises);
}

/**
 * Returns the nested messages within a conditional
 */
export const getConditionalNestedMessages = (
  message: ConditionalMessageRecord,
) => {
  let messages = Immutable.List();
  message.get("statements").forEach((statement) => {
    statement.get("messages")?.forEach((nestedMessage) => {
      messages = messages.push(nestedMessage);
    });
  });

  return messages;
};

/**
 * Returns the nested messages within a scheduled block
 */
export const getScheduleNestedMessages = (message: ScheduledBlockRecord) =>
  message.affirmativeMessages.concat(message.negativeMessages);

/**
 * Returns an array of variable ids attached to message
 */
function getVariableIdsFromMessage(message: MessageRecord) {
  let variableIds;

  switch (message.type) {
    case "action_integration": {
      variableIds = message.outputs.map((output) => output.get("variableId"));
      break;
    }

    case "capture":
    // fallthrough

    case "list_selection_template": {
      variableIds = [message.variableId];
      break;
    }

    case "variable_override":
    // fallthrough

    case "widget":
    // fallthrough

    case "http_request_recipe": {
      variableIds = (message as HTTPMessageRecord).variablesData.map((v) =>
        v.get("variableId"),
      );
      break;
    }

    default: {
      variableIds = [];
    }
  }

  return variableIds;
}

interface DeleteMessageVariablesArgs {
  responseId: string;
  keyPath: KeyPath;
}

/**
 * Deletes a given message's variables _in state_
 */
export function deleteMessageVariables({
  responseId,
  keyPath,
}: DeleteMessageVariablesArgs): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { responsesLoaded } = state;

    const message = responsesLoaded.getIn(keyPath);
    let messagesToDelete: Immutable.List<MessageRecord> | undefined;

    switch (message.type) {
      case "conditionals_block":
        messagesToDelete = getConditionalNestedMessages(message);
        break;
      case "scheduled_block":
        messagesToDelete = getScheduleNestedMessages(message);
        break;
      case "action_integration":
      case "capture":
      case "variable_override":
      case "widget":
      case "http_request_recipe":
      case "list_selection_template":
        messagesToDelete = Immutable.List([message]);
        break;
      default:
        break;
    }

    if (!messagesToDelete) {
      return;
    }

    messagesToDelete = messagesToDelete.filter((msg) =>
      [
        "action_integration",
        "capture",
        "http_request_recipe",
        "variable_override",
        "widget",
        "list_selection_template",
      ].includes(msg.type),
    );

    messagesToDelete.forEach((msg) => {
      const variableIds = getVariableIdsFromMessage(msg);

      variableIds.forEach((variableId) =>
        dispatch(deleteVariableState(variableId, responseId)),
      );
    });
  };
}

/**
 * Deletes all variables associated with the response
 */
export interface DeleteResponseVariablesArgs {
  getState: () => State;
  dispatch: Dispatch;
  responseId: string;
  langToDelete?: LanguageCode | null;
  modality?: Modality;
}

export const deleteResponseVariables = ({
  getState,
  dispatch,
  responseId,
  langToDelete = null,
  modality = MESSAGING_MODALITY,
}: DeleteResponseVariablesArgs) => {
  const state = getState();

  const client = selectClient(state);

  if (!client?.features.personalization) {
    return Promise.resolve();
  }

  const { responsesLoaded } = state;
  const response = responsesLoaded.get(responseId);

  if (!response) {
    throw new Error(`Response ${responseId} not found in state`);
  }

  const languagesToDelete = langToDelete
    ? ([langToDelete] as const)
    : response.messages.keySeq();

  languagesToDelete.forEach((language) => {
    response
      .getIn([
        modality === VOICE_MODALITY ? "messagesVoice" : "messages",
        language,
      ])
      .forEach((message: MessageRecord, messageIndex: number) => {
        const keyPath = [
          responseId,
          modality === VOICE_MODALITY ? "messagesVoice" : "messages",
          language,
          messageIndex,
        ] as const;
        dispatch(
          deleteMessageVariables({
            responseId,
            keyPath,
          }),
        );
      });
  });

  // when we delete a language, don't save to the backend
  if (!langToDelete) {
    return getVariablesPromises(responseId, dispatch, getState);
  }

  return Promise.resolve();
};

export const getGroomedVariables = (
  responseId: string,
  state: State,
): BotContentRequest[] => {
  const client = selectClient(state);

  if (!client?.features.personalization) {
    return [];
  }

  const emptyList = Immutable.List();

  let allVariables = getAllVariablesFlat(state, responseId);
  allVariables = prepareVariables(allVariables, responseId);
  const groupedVariables = groupVariablesByAction(allVariables, responseId);

  const variablesToUpdate = groupedVariables
    .get("update", emptyList)
    .map((variable) => {
      let newVariable = variable;
      const responseReferences = variable.responseReferences.filter(
        (reference: string) => reference,
      );
      newVariable = variable.set("responseReferences", responseReferences);

      return newVariable;
    });

  const createRequests = groupedVariables
    .get("create", emptyList)
    .map((variable) => ({
      resource: "variables",
      method: "POST",
      data: keyConverter(variable.toJS(), "underscore"),
    }));

  const updateRequests = variablesToUpdate.map((variable) => ({
    resource: "variables",
    method: "PATCH",
    data: keyConverter(variable.toJS(), "underscore"),
  }));

  return [
    ...createRequests.toArray(),
    ...updateRequests.toArray(),
  ] as BotContentRequest[];
};
