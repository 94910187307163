import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import {
  parseRuleQuery,
  translateRuleToPlainText,
  translateRuleToPlainTextV1,
} from "services/rules";
import { useVariables } from "services/variables";

export const RuleQueryInlineView = ({
  ruleQuery,
}: {
  ruleQuery: string | null | undefined;
}) => {
  const { variables } = useVariables();
  const flags = useFlags();

  if (!ruleQuery) return <>Everyone</>;

  const parsedRule = parseRuleQuery(ruleQuery);

  if (!parsedRule) return <>Unknown</>;

  const getVariableName = (id: string): string =>
    variables?.find((v) => v._id === id)?.name || "unknown_variable";

  if (flags["train-rules-editor-v2"]) {
    return <>{translateRuleToPlainText(parsedRule, getVariableName)}</>;
  }

  return <>{translateRuleToPlainTextV1(parsedRule, getVariableName)}</>;
};
